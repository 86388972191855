import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { map, mergeMap, forkJoin, filter } from 'rxjs';
import { astonCacheId, mergeRecursive, IDocumentFile, sharePrefix, AmountPipe, PluralPipe, AppErrorHandler, ScrolledByService } from '@aston/foundation';
import { DEBUG_BAR_CONFIG, DebugBarConfig } from '@aston/foundation/components'
import { FeatureFlagsService } from '@aston/feature-flags';
import { ErrorHandler, Provider, inject } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';

import { environment } from '../environments/environment';

import * as RoutesDefinitions from './routes-definitions';
import { AppConfiguration } from './app.configuration';
import { currentTenantId } from './authentication-module/functions';
import { AppStoreSelectors, AppStoreActions } from './root-store/app-store';
import { ReferentialStoreSelectors } from './root-store/referential-store';
import { DefaultFeatureFlags } from './app.feature-flags';
import { InactivityChannelService, WebSocketService } from './shared-module/services';
import { AuthenticationService } from './authentication-module/services';

export const apiRoot = (store: Store) => store.select(AppStoreSelectors.selectApiUrl)

export function translateLoader(
	http: HttpClient,
	config: AppConfiguration) {

	const filesToConsider = environment.translations.merged ?
		[{ prefix: './assets/i18n/global.', suffix: '.json' }] :
		environment.translations.filenames.map(f => {
			return { prefix: './assets/i18n/' + f + '.', suffix: '.json' };
		})
	return new MultiTranslateHttpLoader(http, config, filesToConsider);
}

/* custom translation loader, extends the translation file to merge multiple files into a single one */

/* original => https://github.com/ngx-translate/http-loader/issues/44#issuecomment-412510056 */
export class MultiTranslateHttpLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		private config: AppConfiguration,
		public resources: { prefix: string, suffix: string }[] = [{ prefix: '/assets/i18n/', suffix: '.json' }]) {
	}

	public getTranslation(lang: string) {
		return this.config.appConfigLoaded$.pipe(
			map(_ => {
				const matcher = new RegExp(this.config.translationsMatcher);
				const overrideToken = this.config.overrideTranslationsWith;
				const resourcesNames = this.resources
					.filter(resourceName => resourceName.prefix.includes('global') || matcher.test(resourceName.prefix))
					.sort((aString, bString) => {
						// we want override files to be at the end.
						const a = aString.prefix.indexOf(overrideToken);
						const b = bString.prefix.indexOf(overrideToken);
						if (a > b) {
							return 1;
						}
						if (b > a) {
							return -1;
						}
						return 0;
					});

				return resourcesNames.map(resourceName => this.http.get(`${resourceName.prefix}${lang}${resourceName.suffix}${astonCacheId()}`));
			}),
			mergeMap(calls => forkJoin(calls)),
			map(responses => responses.reduce((a, b) => mergeRecursive(a, b))));
	}
}

export const ctxHelpConnector = (store: Store) => ({
	cxtHelpBaseUrl: store.select(AppStoreSelectors.selectConfig).pipe(map(c => c?.knowledgeBaseUrl))
})

export const allianzTradeConnector = (store: Store) => ({
	apiUrl: store.select(AppStoreSelectors.selectApiUrl),
})

export const ellisphereConnector = (store: Store) => ({
	apiUrl: store.select(AppStoreSelectors.selectApiUrl),
	countries: store.select(ReferentialStoreSelectors.selectCountriesInCurrentLanguage),
	states: store.select(ReferentialStoreSelectors.selectStatesInCurrentLanguage),
	download: (document: IDocumentFile) => store.dispatch(AppStoreActions.OpenDocument({ document })),
});

export function getBaseLocation() {
	return `/${currentTenantId()||''}`;
}

export function getHomeLocation(ffs: FeatureFlagsService) {
	return ffs.isFeatureEnabled('chartsHomepage')
		? RoutesDefinitions.getHomeFullPath()
		: RoutesDefinitions.getTasksFullPath()
}

export function appConfigurationFactory(store: Store): () => Promise<boolean> {
	return () => new Promise(resolve => {
		store.dispatch(AppStoreActions.StartInitializer());
		store.select(AppStoreSelectors.selectIsInitFinished).pipe(filter(Boolean)).subscribe(_ => {
			resolve(true);
		});
	});
}

export function providePipes(): Provider[] {
	return [
		AmountPipe,
		PluralPipe,
	]
}

export function provideDebugBar() {
	return [{
		provide: DEBUG_BAR_CONFIG,
		useFactory: (
			errorHandler: ErrorHandler,
			configuration: AppConfiguration,
			featureFlagsService: FeatureFlagsService,
			scrollService: ScrolledByService,
			store: Store,
			inactivityService: InactivityChannelService,
			auth: AuthenticationService,
			websocket: WebSocketService,
		): DebugBarConfig => ({
			configuration,
			scrollService,
			errors: errorHandler as AppErrorHandler,
			defaultFeatureFlags: Object.keys(DefaultFeatureFlags),
			featureFlagsService,
			tenant$: store.select(AppStoreSelectors.selectTenant),
			sessionId$: store.select(AppStoreSelectors.selectSessionId),
			inactivityService,
			websocket,
			auth,
			supportImpersonation: !environment.dev ? null : () => store.dispatch(AppStoreActions.ImpersonateSupport()),
		}),
		deps: [
			ErrorHandler,
			AppConfiguration,
			FeatureFlagsService,
			ScrolledByService,
			Store,
			InactivityChannelService,
			AuthenticationService,
			WebSocketService
		]
	}]
}

export function onViewTransitionCreated({transition}) {
	const router = inject(Router);
	const currentUrl = router.lastSuccessfulNavigation.finalUrl;
	const targetUrl = router.getCurrentNavigation().finalUrl;
	if (!currentUrl || !targetUrl) return false;

	// Skip the transition if the only thing
	// changing is the fragment and queryParams
	const config: IsActiveMatchOptions = {
		paths: 'subset',
		matrixParams: 'ignored',
		fragment: 'ignored',
		queryParams: 'ignored',
	};
	if (router.isActive(targetUrl, config)
		// Also skip between two same /word/:id/deep/pages
		|| sharePrefix(router.serializeUrl(currentUrl), router.serializeUrl(targetUrl))
	) {
		transition.skipTransition();
	}
}
