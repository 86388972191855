<header class="menu-header">
	<div class="menu-icon">
		<button class="menu-toggle" data-cy="ToggleMenu"
				type="button"
				(click)="onToggle()">
			<span></span>
			<span></span>
			<span></span>
		</button>
	</div>

	<div class="menu-logo">
		<a data-cy="MenuParent"
		   data-testid="MenuParent.Logo"
		   [routerLink]="routes.homePage"
		   routerLinkActive="active">
			<img src="/assets/images/logos/logo_aston_cash_collection.svg">
		</a>

	</div>
</header>

<ul #menulinks class="menu-links">
	<li>
		<a data-cy="MenuParent"
		   data-testid="MenuParent.HomePage"
		   [routerLink]="routes.homePage"
		   routerLinkActive="active">
			<i class="menu-icon fac fa-custom-home"></i>
			<span>{{ 'Menu.HomePage.Title' | translate }}</span>
		</a>
		<ul class="submenu-links">
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.Overview"
				   [routerLink]="routes.overviewPage"
				   routerLinkActive="active">{{ 'Menu.HomePage.Item1' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.Dashboards"
				   [routerLink]="routes.dashboardPage"
				   routerLinkActive="active">{{ 'Menu.HomePage.Item2' | translate }}</a>
			</li>
			@if (hasTeamFollowUpClearanceLevel) {
				<li>
					<a data-cy="MenuItem"
						 data-testid="MenuItem.TeamDashboard"
						 [routerLink]="routes.teamDashboardPage"
						 routerLinkActive="active">{{ 'Menu.HomePage.Item3' | translate }}</a>
				</li>
			}
		</ul>
	</li>
	<li>
		<a data-cy="MenuParent"
		   data-testid="MenuParent.SuperDebtorsList"
		   [routerLink]="routes.superDebtorsList"
		   [class.active]="specialActiveCases(routes.superDebtorsList)">
			<i class="menu-icon fac fa-custom-portfolio"></i>
			<span>{{ 'Menu.SuperDebtors' | translate }}</span>
		</a>
	</li>
	<li>
		<a data-cy="MenuParent"
		   data-testid="MenuParent.TransfersList"
		   [routerLink]="routes.accountingList"
		   routerLinkActive="active">
			<i class="menu-icon fac fa-custom-paperclip"></i>
			<span>{{ 'Menu.Accounting.Title' | translate }}</span>
		</a>
	</li>
	<li>
		<a data-cy="MenuParent"
		   data-testid="MenuParent.Dunning"
		   [routerLink]="routes.dunningActionsListAssigned"
		   [class.active]="specialActiveCases(routes.dunning)">
			<i class="menu-icon fac fa-custom-cash"></i>
			<span>{{ 'Menu.Dunning.Title' | translate }}</span>
		</a>

		<ul class="submenu-links">
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.DunningActionsList"
				   [routerLink]="routes.dunningActionsListAssigned"
				   routerLinkActive="active">{{ 'Menu.Dunning.Item5' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.DunningCasesList"
				   [routerLink]="routes.dunningCasesList"
				   routerLinkActive="active">{{ 'Menu.Dunning.Item1' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.DunningGroupsList"
				   [routerLink]="routes.dunningGroupsList"
				   routerLinkActive="active">{{ 'Menu.Dunning.Item2' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.DunningTemplatesList"
				   [routerLink]="routes.dunningTemplatesList"
				   routerLinkActive="active">{{ 'Menu.Dunning.Item3' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.DunningScenariosList"
				   [routerLink]="routes.dunningScenariosList"
				   routerLinkActive="active">{{ 'Menu.Dunning.Item4' | translate }}</a>
			</li>
			@if (hasReadOnlyAccessToAllSuperDebtorsClearanceLevel) {
				<li>
					<a data-cy="MenuItem"
					data-testid="MenuItem.DunningShipmentHistory"
					[routerLink]="routes.dunningShipmentHistory"
					routerLinkActive="active">{{ 'Menu.Dunning.Item6' | translate }}</a>
				</li>
			}
			<li>
				<a data-cy="MenuItem"
				   data-testid="MenuItem.DunningContactsList"
				   [routerLink]="routes.dunningContactsList"
				   routerLinkActive="active">{{ 'Menu.Dunning.Item7' | translate }}</a>
			</li>
		</ul>
	</li>

	@if (isImportMenuDisabled() === false && (hasManualImportClearanceLevel || hasImportHistoryClearanceLevel)) {
		<li>
			<a data-cy="MenuParent"
				 data-testid="MenuParent.ImportCreation"
				 [routerLink]="routes.importRoot"
				 routerLinkActive="active">
				<i class="menu-icon far fa-file-import"></i>
				<span>{{ 'Menu.Import.Title' | translate }}</span>
			</a>
	
			<ul class="submenu-links">
				@if (hasManualImportClearanceLevel) {
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.ImportCreation"
							 [routerLink]="routes.importCreation"
							 routerLinkActive="active">{{ 'Menu.Import.Item1' | translate }}</a>
					</li>
				}
				@if (hasImportHistoryClearanceLevel) {
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.ImportList"
							 [routerLink]="routes.importList"
							 routerLinkActive="active">{{ 'Menu.Import.Item2' | translate }}</a>
					</li>
				}
			</ul>
		</li>
	}

	@if (hasAdministrationToolsClearanceLevel || hasSuperAdministrationClearanceLevel) {
		<li>
			<a data-cy="MenuParent"
				 data-testid="MenuParent.AdminToolsPage"
				 [routerLink]="routes.adminToolsPage"
				 routerLinkActive="active">
				<i class="menu-icon far fa-cog"></i>
				<span>{{ 'Menu.AdminTools.Title' | translate }}</span>
			</a>
	
			<ul class="submenu-links">
				@if (hasAdministrationToolsClearanceLevel) {
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.AdminToolsPage"
							 [routerLink]="routes.adminToolsOptionsPage"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item11' | translate }}</a>
					</li>
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.AdminIndicators"
							 [routerLink]="routes.adminToolsIndicators"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item8' | translate }}</a>
					</li>
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.adminToolsClaimTypes"
							 [routerLink]="routes.adminToolsClaimTypes"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item12' | translate }}</a>
					</li>
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.adminToolsExtradataConfig"
							 [routerLink]="routes.adminToolsExtradataConfig"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item9' | translate }}</a>
					</li>
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.adminToolsPortfolioRights"
							 [routerLink]="routes.adminToolsPortfolioRights"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item10' | translate }}</a>
					</li>
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.adminToolsUserReports"
							 [routerLink]="routes.adminToolsUserReports"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item13' | translate }}</a>
					</li>
				}
				@if (hasSuperAdministrationClearanceLevel) {
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.adminToolsWorkers"
							 [routerLink]="routes.adminToolsWorkers"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item4' | translate }}</a>
					</li>
					<li>
						<a data-cy="MenuItem"
							 data-testid="MenuItem.adminToolsWorkflows"
							 [routerLink]="routes.adminToolsWorkflowSchedulingInstancesList"
							 routerLinkActive="active">{{ 'Menu.AdminTools.Item5' | translate }}</a>
					</li>
				}
			</ul>
		</li>
	}

</ul>

<ul class="menu-bookmarks">
	@for (item of bookmarksStore.items(); track item.url) {
		<bookmark [bookmark]="item" (remove)="bookmarksStore.remove($event)" />
	}
</ul>
