<div *ngIf="(onLine$ | async) !== true" class="offline-alert text-center p-2" translate="NotificationMessages.Errors.Offline"></div>
<div *ngIf="ws.connectWarning$ | async" class="offline-alert ws-offline-alert text-center p-2 cursor-pointer" translate="NotificationMessages.Errors.OfflineWebSocket" (click)="location.reload()"></div>

<ng-container *ngIf="appIsReady$ | async">
    <div class="{{layoutName}}-page">
        <router-outlet></router-outlet>
    </div>

    <div class="page-loader" [class.is-loading]="pageLoader.isLoading$ | async">
        <loader-spinner [model]="pageLoader"/>
    </div>

    <page-anchors [anchors]="pageAnchors"/>

    <div class="page-notifications">
        <notification-message *ngFor="let notification of notifications; let index = index" [notification]="notification"
                            (dismissEvent)="removeNotification(index)"/>
    </div>
</ng-container>
